import { default as _4049kH3aScAvVMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/404.vue?macro=true";
import { default as _91_46_46_46slug_936YHecDYVzsMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/[...slug].vue?macro=true";
import { default as _91_91filter_93_933um2E8OTfAMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexiH9UxLNblmMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/account/index.vue?macro=true";
import { default as notificationsmTCPCPRkltMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/account/notifications.vue?macro=true";
import { default as paymentsERjyeSfvV9Meta } from "/codebuild/output/src3231331204/src/gx-booking/pages/account/payments.vue?macro=true";
import { default as personal_45infoRv4EFU8aasMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/account/personal-info.vue?macro=true";
import { default as securityhP1VV7NvOfMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/account/security.vue?macro=true";
import { default as confirmYDE8MkxSKWMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexkkpHUUeL4kMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93jEvS2ZL3kWMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93DulxQSkdLJMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/gratuity/[accessKey].vue?macro=true";
import { default as login3c6ZWCqqDNMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/login.vue?macro=true";
import { default as reset_45passwordfdOsmd7o2NMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/reset-password.vue?macro=true";
import { default as set_45passwordX3MD0Hr1CVMeta } from "/codebuild/output/src3231331204/src/gx-booking/pages/set-password.vue?macro=true";
export default [
  {
    name: _4049kH3aScAvVMeta?.name ?? "404",
    path: _4049kH3aScAvVMeta?.path ?? "/404",
    meta: _4049kH3aScAvVMeta || {},
    alias: _4049kH3aScAvVMeta?.alias || [],
    redirect: _4049kH3aScAvVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_936YHecDYVzsMeta?.name ?? "slug",
    path: _91_46_46_46slug_936YHecDYVzsMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_936YHecDYVzsMeta || {},
    alias: _91_46_46_46slug_936YHecDYVzsMeta?.alias || [],
    redirect: _91_46_46_46slug_936YHecDYVzsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_933um2E8OTfAMeta?.name ?? "account-bookings-filter",
    path: _91_91filter_93_933um2E8OTfAMeta?.path ?? "/account/bookings/:filter?",
    meta: _91_91filter_93_933um2E8OTfAMeta || {},
    alias: _91_91filter_93_933um2E8OTfAMeta?.alias || [],
    redirect: _91_91filter_93_933um2E8OTfAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/account/bookings/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: indexiH9UxLNblmMeta?.name ?? "account",
    path: indexiH9UxLNblmMeta?.path ?? "/account",
    meta: indexiH9UxLNblmMeta || {},
    alias: indexiH9UxLNblmMeta?.alias || [],
    redirect: indexiH9UxLNblmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsmTCPCPRkltMeta?.name ?? "account-notifications",
    path: notificationsmTCPCPRkltMeta?.path ?? "/account/notifications",
    meta: notificationsmTCPCPRkltMeta || {},
    alias: notificationsmTCPCPRkltMeta?.alias || [],
    redirect: notificationsmTCPCPRkltMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: paymentsERjyeSfvV9Meta?.name ?? "account-payments",
    path: paymentsERjyeSfvV9Meta?.path ?? "/account/payments",
    meta: paymentsERjyeSfvV9Meta || {},
    alias: paymentsERjyeSfvV9Meta?.alias || [],
    redirect: paymentsERjyeSfvV9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/account/payments.vue").then(m => m.default || m)
  },
  {
    name: personal_45infoRv4EFU8aasMeta?.name ?? "account-personal-info",
    path: personal_45infoRv4EFU8aasMeta?.path ?? "/account/personal-info",
    meta: personal_45infoRv4EFU8aasMeta || {},
    alias: personal_45infoRv4EFU8aasMeta?.alias || [],
    redirect: personal_45infoRv4EFU8aasMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/account/personal-info.vue").then(m => m.default || m)
  },
  {
    name: securityhP1VV7NvOfMeta?.name ?? "account-security",
    path: securityhP1VV7NvOfMeta?.path ?? "/account/security",
    meta: securityhP1VV7NvOfMeta || {},
    alias: securityhP1VV7NvOfMeta?.alias || [],
    redirect: securityhP1VV7NvOfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: confirmYDE8MkxSKWMeta?.name ?? "experiences-parentId-serviceId-confirm",
    path: confirmYDE8MkxSKWMeta?.path ?? "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmYDE8MkxSKWMeta || {},
    alias: confirmYDE8MkxSKWMeta?.alias || [],
    redirect: confirmYDE8MkxSKWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexkkpHUUeL4kMeta?.name ?? "experiences-parentId-serviceId",
    path: indexkkpHUUeL4kMeta?.path ?? "/experiences/:parentId()/:serviceId()",
    meta: indexkkpHUUeL4kMeta || {},
    alias: indexkkpHUUeL4kMeta?.alias || [],
    redirect: indexkkpHUUeL4kMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93jEvS2ZL3kWMeta?.name ?? "experiences-parentId-serviceId-return-bookingId",
    path: _91bookingId_93jEvS2ZL3kWMeta?.path ?? "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93jEvS2ZL3kWMeta || {},
    alias: _91bookingId_93jEvS2ZL3kWMeta?.alias || [],
    redirect: _91bookingId_93jEvS2ZL3kWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91accessKey_93DulxQSkdLJMeta?.name ?? "gratuity-accessKey",
    path: _91accessKey_93DulxQSkdLJMeta?.path ?? "/gratuity/:accessKey()",
    meta: _91accessKey_93DulxQSkdLJMeta || {},
    alias: _91accessKey_93DulxQSkdLJMeta?.alias || [],
    redirect: _91accessKey_93DulxQSkdLJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/gratuity/[accessKey].vue").then(m => m.default || m)
  },
  {
    name: login3c6ZWCqqDNMeta?.name ?? "login",
    path: login3c6ZWCqqDNMeta?.path ?? "/login",
    meta: login3c6ZWCqqDNMeta || {},
    alias: login3c6ZWCqqDNMeta?.alias || [],
    redirect: login3c6ZWCqqDNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordfdOsmd7o2NMeta?.name ?? "reset-password",
    path: reset_45passwordfdOsmd7o2NMeta?.path ?? "/reset-password",
    meta: reset_45passwordfdOsmd7o2NMeta || {},
    alias: reset_45passwordfdOsmd7o2NMeta?.alias || [],
    redirect: reset_45passwordfdOsmd7o2NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: set_45passwordX3MD0Hr1CVMeta?.name ?? "set-password",
    path: set_45passwordX3MD0Hr1CVMeta?.path ?? "/set-password",
    meta: set_45passwordX3MD0Hr1CVMeta || {},
    alias: set_45passwordX3MD0Hr1CVMeta?.alias || [],
    redirect: set_45passwordX3MD0Hr1CVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3231331204/src/gx-booking/pages/set-password.vue").then(m => m.default || m)
  }
]